<template>
	<div class="interaction-content d-flex flex-column flex-grow-1">
		<div>
			<div class="d-flex p-10 align-items-center">
				<div
					v-if="withContact"
					class="ml-0"
				>
					<div
						v-if="emailInfo.subject"
						data-qa="interactionContent_text_subject"
					>
						<span class="font-weight-bold">Subject:</span> {{ emailInfo.subject }}
					</div>
				</div>
				<div class="d-flex ml-auto mr-0">
					<button
						v-if="resendCommsAvailable"
						type="button"
						class="btn btn-primary mr-10"
						data-qa="interactionContent_button_resend"
						@click="$emit('resend-comms')"
					>
						Resend Communication
					</button>
					<button
						type="button"
						class="btn btn-secondary mr-10"
						data-qa="interactionContent_button_close"
						@click="$emit('close')"
					>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary mr-10"
						data-qa="interactionContent_button_save"
						@click="downloadFile"
					>
						Save
					</button>
				</div>
			</div>
			<div
				class="d-flex px-10 py-20 comm-contacts"
				v-if="withContact"
				data-qa="interactionContent_text_contactInfo"
			>
				<span class="font-weight-bold mr-6">From:</span> {{ emailInfo.from }}
				<span class="font-weight-bold mr-6 ml-10">To: </span> {{ emailInfo.to }}
				<span class="d-block mr-10 ml-auto">{{ emailInfo.dateTime }}</span>
			</div>
		</div>
		<div
			v-if="attachments"
			class="d-flex px-20 py-10"
		>
			<div
				v-for="(attachment, index) of attachments"
				:key="index"
				class="d-flex align-items-center cursor-pointer px-10 py-8 mr-6 attachment"
				data-qa="interactionContent_button_attachment"
				@click="downloadAttachment(attachment)"
			>
				<div class="flex-shrink-0 card-icon small mx-8">
					<i class="fas fa-paperclip" />
				</div>
				{{ attachment.attachmentName }}
			</div>
		</div>
		<div class="p-20 interaction-document">
			<div
				class="h-100"
				v-if="isDocument"
			>
				<document-preview
					class="h-100"
					ref="docPreview"
					v-if="documentSrc"
					:doc-src="documentSrc"
					:file-name="previewContent.fileName"
					:doc-mime-type="docMimeType"
					:file-id="fileId"
				/>
			</div>
			<MarkdownWrapper
				v-else-if="documentContent && isHtmlContent"
				:content="documentContent"
				:source="documentSrc"
				data-qa="interactionContent_view_htmlContent"
			/>
			<div
				class="h-100  px-10"
				v-else-if="isText"
				data-qa="interactionContent_text_smsContent"
			>
				{{ smsContent }}
			</div>
		</div>
	</div>
</template>

<script>
import { communicationType } from '@commonServices/models/CommunicationType';
import { shortDateTimeDay } from '@commonServices/utils/filters';
import FileService from '@commonServices/fileService';
import { getFileExtension, MimeTypes } from '@commonServices/utils/general';
import MarkdownWrapper from '@commonView/Shared/MarkdownWrapper';

export default {
	name: 'InteractionContent',
	props: {
		previewContent: {
			type: Object,
			required: true,
		},
	},
	components: {
		MarkdownWrapper,
	},
	data () {
		return {
			documentSrc: null,
			documentContent: null,
			fileId: null,
			emailData: null,
		};
	},
	watch: {
		previewContent: {
			handler (oldValue) {
				if (this.fileId !== oldValue.fileId) {
					this.generateFilePreview();
				}
			},
			immediate: true,
		},
	},
	methods: {
		async generateFilePreview () {
			this.documentContent = null;
			this.fileId = this.previewContent.fileId;
			if (this.isInboundEmail) {
				this.emailData = await FileService.getEmailData(this.previewContent.fileId);
				this.documentContent = this.emailData.htmlBody;
			} else if (this.isDocument) {
				await this.setDocumentSrc();
			} else if (this.isEmail || this.isHtml) {
				await Promise.all([this.setDocumentSrc(), this.getRawFileContent()]);
			}
		},
		async getRawFileContent () {
			const apiFileSrc = FileService.getFileSrc(this.fileId);
			const fileBlob = await FileService.getFileBlob(apiFileSrc, 'text/html');
			this.documentContent = await this.read(fileBlob);
		},
		async read (fileBlob) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = (event) => resolve(event.target.result);
				reader.onerror = reject;
				reader.readAsText(fileBlob);
			});
		},
		async setDocumentSrc () {
			this.documentSrc = await FileService.getTempSrc(this.fileId, this.docMimeType);
		},
		async downloadFile () {
			return await FileService.downloadFile(this.fileId, this.previewContent.fileName, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
		},
		async downloadAttachment ({ attachmentId, attachmentName }) {
			if (this.isInboundEmail) {
				return await FileService.getEmailAttachment(this.fileId, attachmentName, attachmentId, MimeTypes[getFileExtension(attachmentName)]);
			}
			return await FileService.downloadFile(attachmentId, attachmentName, MimeTypes[getFileExtension(attachmentName)]);
		},
	},
	computed: {
		emailInfo () {
			if (this.isInboundEmail) {
				return {
					from: this.emailData?.sender,
					to: this.emailData?.recipients,
					subject: this.emailData?.subject,
					dateTime: shortDateTimeDay(this.emailData?.sentOn),
				};
			} else {
				return {
					from: this.previewContent.commDetails?.from,
					to: this.previewContent.commDetails?.to,
					subject: this.previewContent.commDetails?.subject,
					dateTime: shortDateTimeDay(this.previewContent.dateTime),
				};
			}
		},
		commType () {
			return this.previewContent.commType;
		},
		isDocument () {
			return [communicationType.Document, communicationType.Letter].includes(this.commType);
		},
		isEmail () {
			return this.commType === communicationType.Email;
		},
		isText () {
			return this.commType === communicationType.Sms;
		},
		isHtml () {
			return this.commType === communicationType.Html;
		},
		isInboundEmail () {
			return this.commType === communicationType.InboundEmail;
		},
		attachments () {
			if (this.previewContent.attachments && this.previewContent.attachments.length) {
				return this.previewContent.attachments;
			}
			if (this.isInboundEmail && this.emailData?.attachments.length) {
				return this.emailData.attachments;
			}
			return null;
		},
		withContact () {
			return [communicationType.Email, communicationType.Sms].includes(this.commType)
				|| this.isInboundEmail;
		},
		smsContent () {
			if (this.isText) {
				return this.previewContent.commDetails.data;
			}
			return null;
		},
		docMimeType () {
			return MimeTypes[getFileExtension(this.previewContent.fileName)];
		},
		isHtmlContent () {
			return this.isEmail || this.isHtml || this.isInboundEmail;
		},
		resendCommsAvailable () {
			return [communicationType.Email, communicationType.Letter, communicationType.Sms].includes(this.commType);
		},
	},
};
</script>

<style lang="scss" scoped>
.interaction-content {
  position: fixed;
  left: $navWidth;
  width: calc(100% - #{$historyPanelWidth} - #{$navWidth});
  height: calc(100% - #{$headerHeight});
  z-index: 5;
  background: var(--bodyBg);

  .comm-contacts {
    background: var(--footerBg);
  }

  .attachment {
    background: var(--contentBg);
  }

  .interaction-document {
    height: calc(100% - 57px);
  }

  .html-container {
    background-color: white;
    overflow: hidden auto;
  }
}
</style>
